<template>
    <div class="view-root">
        <h3>主办方的产品和品牌列表</h3>
        <div style="text-align: right;">
            <el-button type="primary" size="small" @click="editContent(null)">添加内容</el-button>
        </div>

        <el-table :data="dataList" style="margin-top: 15px;">
            <el-table-column type="index" prop="index" label="序号" width="100px"></el-table-column>
            <el-table-column prop="name" label="名称"></el-table-column>
            <el-table-column prop="" label="Logo">
                <template slot-scope="scope">
                    <img :src="scope.row.logo_url" style="width: 96px;height: auto;object-fit: cover;">
                </template>
            </el-table-column>
            <el-table-column prop="" label="操作" width="320px">
                <template slot-scope="scope">
                    <el-button type="primary" size="small" @click="editContent(scope.row)">编辑</el-button>
                    <el-button type="danger" size="small" @click="doRemoveProduct(scope.row)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-dialog :visible.sync="showProductDialog" :title="productInfo.id == '' ? '添加产品' : '编辑产品'" width="65%">
            <div>
                <el-form :model="productInfo">
                    <el-form-item label="名称" prop="name">
                        <el-input v-model="productInfo.name" placeholder="请输入"></el-input>
                    </el-form-item>
                    <el-form-item label="Logo">
                        <el-upload action="/" :show-file-list="false" :before-upload="(file) => beforeLogoUpload(file)">
                            <img v-if="productInfo.logo_url" :src="productInfo.logo_url" class="logo-uploader">
                            <!-- <i v-else class="el-icon-plus avatar-uploader-icon"></i> -->
                            <i class="el-icon-plus logo-uploader2"></i>
                        </el-upload>
                    </el-form-item>
                    <el-form-item label="">
                        <label>介绍</label>
                        <quill-editor class="editor" style="margin-bottom:20px" ref="myTextEditor"
                            v-model="productInfo.intro" :options="editorOption">
                        </quill-editor>
                    </el-form-item>
                </el-form>
                <div style="text-align: right;">
                    <span slot="footer" class="dialog-footer">
                        <el-button @click="showProductDialog = false">取 消</el-button>
                        <el-button type="primary" @click="saveContent()">确 定</el-button>
                    </span>
                </div>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import {
    getProducts,
    removeProduct,
    updateProduct,
    getQiniuToken,
} from '../../api/api'
import '../../assets/common/common.css'
import * as qiniu from "qiniu-js"
export default ({
    name: 'index',
    data() {
        return {
            organizer_id: this.$route.query.organizer_id,
            dataList: [],
            productInfo: {
                id: '',
                organizer_id: this.organizer_id,
                name: '',
                logo_url: '',
                intro: ''
            },
            editorOption: {
                modules: {
                    toolbar: [
                        ['bold', 'italic', 'underline', 'strike'], // 加粗 斜体 下划线 删除线
                        ['blockquote', 'code-block'], // 引用  代码块
                        [{ header: 1 }, { header: 2 }], // 1、2 级标题
                        [{ list: 'ordered' }, { list: 'bullet' }], // 有序、无序列表
                        [{ script: 'sub' }, { script: 'super' }], // 上标/下标
                        [{ indent: '-1' }, { indent: '+1' }], // 缩进
                        // [{'direction': 'rtl'}],                         // 文本方向
                        [{ size: ['small', false, 'large', 'huge'] }], // 字体大小
                        [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题
                        [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
                        [{ font: [] }], // 字体种类
                        [{ align: [] }], // 对齐方式
                        ['clean'], // 清除文本格式
                        ['link', 'image', 'video'] // 链接、图片、视频
                    ] // 工具菜单栏配置
                },
                placeholder: '', // 提示
                readyOnly: false, // 是否只读
                theme: 'snow', // 主题 snow/bubble
                syntax: true // 语法检测
            },
            showProductDialog: false
        }
    },
    mounted() {
        this.requestData()
    },
    methods: {

        doRemoveProduct(row) {
            this.$alert('你确定要删除此产品吗？', '提示', {
                confirmButtonText: '确定',
                callback: action => {
                    if (action == 'confirm') {
                        removeProduct(row._id.$id).then((res) => {
                            this.$message.success('删除成功')
                            this.requestData()
                        })
                    }
                }
            })
        },

        requestData() {
            getProducts(this.organizer_id).then((res) => {
                this.dataList = res.data.data
            })
        },
        editContent(row) {
            if (row) {
                this.productInfo.id = row._id.$id,
                    this.productInfo.organizer_id = row.organizer_id,
                    this.productInfo.name = row.name
                this.productInfo.logo_url = row.logo_url
                this.productInfo.intro = row.intro
            } else {
                this.productInfo.id = ''
                this.productInfo.organizer_id = this.organizer_id
                this.productInfo.name = ''
                this.productInfo.logo_url = ''
                this.productInfo.intro = ''
            }
            this.showProductDialog = true
        },
        async beforeLogoUpload(file) {
            const result = await getQiniuToken()
            if (result.data.code === 0) {
                const token = result.data.data
                this.observable = qiniu.upload(
                    file,
                    new Date().getTime() + file.name,
                    token
                )
                const observer = {
                    complete: (res) => {
                        this.productInfo.logo_url = "http://mainbucket.reachable-edu.com/" + res.key
                    },
                }
                this.observable.subscribe(observer)
            }
        },
        saveContent() {
            updateProduct(this.productInfo).then((res) => {
                this.$message.success('保存成功')
                this.requestData()
                this.showProductDialog = false

            })
        }
    }
})
</script>
<style>
.ql-container {
    height: 160px !important;
}

.logo-uploader {
    width: 178px;
    height: auto;
    object-fit: cover;
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    box-sizing: border-box;
}

.logo-uploader2 {
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
    font-size: 28px;
    color: #8c939d;
    object-fit: cover;
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    box-sizing: border-box;
}
</style>
